/**
 * Load and set-up Knockout.
 *
 * Should be imported at entry point, before anything else.
 * Later usages of Knockout must import the global module rather than this
 * (actually, this does return nothing, just copy first line here).
 */
import ko from 'knockout';

// Enable Deferred Updates globally (best performance)
ko.options.deferUpdates = true;

// Available globally as 'console devtool'
window._ko = ko;
