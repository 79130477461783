/**
 * Utility to trigger displaying of a modal built with a given html template
 * with Knockout bindings and using Bootstrap modal plugin.
 * It accepts the parameters/options used as ViewModel for the template.
 * Returns a function to trigger hidding of the modal.
 * The modal is created each time is invoked and destroyed on hidding.
 */
import ariaHideElements from './ariaHideElements';
import createElement from './createElement';
import fixFocus from './fixFocus';
import ko from 'knockout';

/**
 * @param {string} template Partial HTML with the expected Bootstrap classes
 * and structure
 * @param {Object} params Arbitrary members to use as the KO ViewModel of the template
 * bindings. There is only one enforced and required member for this
 * @param {Function} params.onHide Required callback that will trigger whenever
 * the modal is hidden, because of calling the returned function or implicit
 * mechanisms to dismiss or close the modal.
 * @returns {Function} Callback with no parameters and no return value that
 * request to close the modal. it's the only recommended way to close it, or
 * inestability will happen.
 */
export default function(template, params) {
    // Required user callback
    if (typeof params.onHide !== 'function') {
        throw new Error('Parameter onHide is required');
    }
    // Prepare the DOM element and attach it
    const $element = createElement(template);
    // Usability and accessibility
    fixFocus($element);
    // Increased accessibility:
    // NOTE: must be reverted BEFORE we fullfill
    var handle = ariaHideElements.keep($element.get(0));
    // On modal hide, revert the previous before anything else,
    // and call the user callback for hiding
    $element
    .off('hide.bs.modal.ariaHide')
    .one('hide.bs.modal.ariaHide', () => {
        handle.revert();
        params.onHide();
    });
    // Binding and display
    ko.applyBindings(params, $element.get(0));
    $element.modal('show');
    // Return a function that allow to hide the modal
    return () => {
        $element.modal('hide');
    };
}
