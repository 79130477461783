/**
 * Logout form-button. No title included.
 *
 * @module kocomponents/account/logout
 */
import Komponent from '../../../vendor/iagosrl/KnockoutComponent';
import ko from 'knockout';
import { logout } from '../../../data/session';
import { show as showError } from '../../../vendor/iagosrl/modals/error';
import template from './template.html';

const TAG_NAME = 'account-logout';

export default class AccountLogout extends Komponent {

    static get template() { return template; }

    constructor() {
        super();

        this.isSending = ko.observable(false);
    }

    async logout() {
        try {
            this.isSending(true);
            await logout();
        }
        catch (ex) {
            await showError({
                title: 'Unable to log-out from server',
                error: ex
            });
        }
        finally {
            this.isSending(false);
        }
    }
}

ko.components.register(TAG_NAME, AccountLogout);

if (module.hot) {
    module.hot.dispose(function () {
        ko.components.unregister(TAG_NAME);
    });
}
