/**
 * Top app navigation menu
 *
 * @module kocomponents/nav/menu
 */
/* global VERSION */
import './style.scss';
import '../../../vendor/iagosrl/cssTransformBinding';
import '../../account/logout';
import Komponent from '../../../vendor/iagosrl/KnockoutComponent';
import { current as currentSession } from '../../../data/session';
import getObservable from '../../../vendor/iagosrl/getObservable';
import ko from 'knockout';
import template from './template.html';

const cssClass = 'NavMenu';
const TAG_NAME = 'nav-menu';

export default class NavMenu extends Komponent {

    static get cssClass() { return cssClass; }

    static get template() { return template; }

    /**
     *
     * @param {Object} params
     * @param {(boolean|KnockoutObservable<boolean>)} params.isOpen Manages whether the menu must be opened
     * @param {(string|KnockoutObservable<string>)} params.activeEntry Editable reference to active menu entry
     */
    constructor(params) {
        super();

        /**
         * @member {KnockoutComputed<Session>}
         */
        this.user = currentSession;
        /**
         * @member {KnockoutObservable<boolean>}
         */
        this.isOpen = getObservable(params.isOpen || false);
        /**
         * Keeps a name for the menu entry that is active at the moment
         * @member {KnockoutObservable<string>}
         */
        this.activeEntry = getObservable(params.activeEntry || '');
        /**
         * App version number (filled in at build time)
         * @const
         * @property {string}
         */
        this.version = VERSION || '';
    }

    /**
     * Set-up visualization of the menu
     * @param {Object} componentInfo
     * @param {HTMLElement} componentInfo.element
     */
    beforeBinding(componentInfo) {
        super.beforeBinding(componentInfo);

        const element = componentInfo.element;
        this.observeChanges(() => {
            element.classList.toggle('in', this.isOpen());
        });

        element.addEventListener('click', () => {
            this.close();
        }, false);
    }

    close() {
        this.isOpen(false);
    }

    /**
     * Gets the list of CSS classes to be applied to the given menu entry
     * @param {string} entryName
     */
    cssFor(entryName) {
        return entryName === this.activeEntry() ? 'is-active' : '';
    }
}

ko.components.register(TAG_NAME, NavMenu);

if (module.hot) {
    module.hot.dispose(function () {
        ko.components.unregister(TAG_NAME);
    });
}
