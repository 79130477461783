/**
 * Localforage data driver.
 * Provides a localforage instance ready
 * to use with the app set-up
 */
'use strict';

import localforage from 'localforage';

localforage.config({
    name: 'fishfarmfeeder-app',
    version: 1.0,
    size : 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName : 'keyvaluepairs',
    description : 'FishFarmFeeder App'
});

export default localforage;
