/**
 * Set-up the App Shell
 */

import $ from 'jquery';
import History from '../vendor/iagosrl/shell/hashbangHistory';
import KoItemsManager from './KoItemsManager';
import Shell from '../vendor/iagosrl/shell/Shell';
import absolutizeUrl from '../vendor/iagosrl/shell/absolutizeUrl';
import ko from 'knockout';
import parseUrl from '../vendor/iagosrl/shell/parseUrl';
import scrollToElement from '../vendor/iagosrl/scrollToElement';

export let shell = null;

/**
 * @param {Object} activitiesIndex Manager for the available list of activities,
 * with method 'get(string)<Promise>' to query and import activities on demand.
 * @param {Object} [options]
 * @param {string} [options.indexName]
 */
export function create(activitiesIndex, options = {}) {
    const { indexName = 'home' } = options;

    var baseUrl = ''; // NEVER: window.location.pathname, because will broke inside Cordova
    var hashBang = true;
    if (!window.cordova) {
        var t = /^http.?:\/\/[^\/]+(\/[^#]*)/.exec(window.document.baseURI);
        if (t && t[1]) {
            baseUrl = t[1];
            hashBang = false;
        }
    }

    const $root = $('#shell');

    // Creating the shell:
    shell = new Shell({

        // Selector, DOM element or jQuery object pointing
        // the root or container for the shell items
        root: $root,

        // If is not in the site root, the base URL is required:
        baseUrl: baseUrl,

        forceHashbang: hashBang,
        // Must be an all-users accesible URL
        indexName,
        // Must be an all-users accesible URL
        forbiddenAccessName: 'login',

        linkEvent: 'click',

        // History Polyfill:
        history: History,
        parseUrl,
        absolutizeUrl,
        jquery: $,

        // Knockout component-based manager
        domItemsManager: new KoItemsManager({
            root: $root.get(0),
            componentLoaded: activitiesIndex.get,
            pattern: '{name}-activity'
        })
    });

    // Enroute activities when they are ready
    // This knows that we use KO for items, following the pattern of a 'route' method
    // at the activity viewmodel/class
    shell.on(shell.events.itemReady, (element, state) => {
        // The element is ever the host of the component, whose viewModel is
        // the management of active item/view/component, while we want the viewModel
        // from the activity instance (that is, anything inside the element)
        const vm = ko.dataFor(element.firstElementChild);
        if (vm && typeof(vm.route) === 'function') {
            try {
                vm.route(state.route);
            }
            catch (ex) {
                console.error(`Error while routing activity ${state.route.name}`);
            }
        }
    });

    /**
     * Additional method, allowing to request scroll the view to an element given the ID
     * and optionally setting the focus properly
     * @param {string} idOrHash
     * @param {boolen} giveFocus
     */
    shell.scrollTo = function(idOrHash, giveFocus) {
        // Clean-up id
        var id = idOrHash.replace(/^#/, '');
        var hash = '#' + id;
        // Locate target
        var target = $(hash);
        if (target.length) {
            // Smooth scrolling with animation
            var opts = {
                topOffset: 0,
                animation: { duration: 300 }
            };

            scrollToElement(target, opts);

            // Accessibility hint:
            if (giveFocus) {
                // Move focus too
                var noTabindex = !target.attr('tabindex');
                if (noTabindex) {
                    // Set-up to allow programatic focus
                    target.attr('tabindex', -1);
                }
                setTimeout(function(){
                    target.focus();
                    // reset tabindex
                    if (noTabindex) {
                        target.removeAttr('tabindex');
                    }
                }, 100);
            }
        }
    };

    // Re-enable the browser default behavior of scrolling to an element when clicking a hash link
    // with the ID, synthetically perform the animated scroll.
    shell.on('fragmentNavigation', function(href) {
        // Check link, avoiding empty links
        // (href comes with the initial hash ever, so empty is just '#')
        if (href === '#') {
            // Notify for debugging, because this may be unwanted
            console.warn(
                'Navigation to an empty fragment, this may be not wanted. ' +
                'For root links, use "/"; on script handled links, call event.preventDefault; ' +
                'A touch event was listened on a link, but not the click event.'
            );
        }
        else {
            shell.scrollTo(href, true);
        }
    });

    return shell;
}
