/**
 * Data module to manage an user session
 */
'use strict';

import ko from 'knockout';
import local from './drivers/localforage';
import remote from './drivers/restClient';

/**
 * @typedef {Session}
 * @property {string} username
 * @property {string} name
 * @property {string} role
 * @property {string} token
 */

/// State
/**
 * @private {KnockoutObservable<Session>}
 */
const currentSession = ko.observable();
/**
 * Session restored yet? It happens automatically on first time someone ask
 * for current session info (see public member `current` later)
 * @private {boolean}
 */
let restored = false;

/**
 * Log-in against remote and store the session as persistent
 * @param {Object} data
 * @returns {Promise<Session>}
 */
export const login = async (data) => {
    const session = await remote.post('login', data);
    await local.setItem('session', session);
    remote.setBearerAuthenticationToken(session.token);
    currentSession(session);
    return session;
};

/**
 * Log-outs from remote and removes local persistent session
 * @param {Object} data
 * @returns {Promise}
 */
export const logout = async () => {
    await remote.post('logout');
    await local.removeItem('session');
    remote.clearAuthorization();
    currentSession(null);
};

/**
 * Try to restore a persisted session, if stored locally, returning the data.
 * Does not fail if no session, just returns no user (it's anonymous).
 * @returns {Promise<Session>}
 */
const restore = async () => {
    const data = await local.getItem('session');
    if (data && data.token) {
        remote.setBearerAuthenticationToken(data.token);
    }
    return data;
};

/**
 * Gets the current or active session.
 * It auto-restores a session on first access
 * @member {KnockoutComputed<Session>}
 */
export const current = ko.pureComputed({
    read: () => {
        if (!restored) {
            restore().then(currentSession);
            restored = true;
        }
        return currentSession();
    }
});
