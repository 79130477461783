/**
    REST API access
**/
'use strict';
var $ = require('jquery');

function Rest(optionsOrUrl) {

    var url = typeof(optionsOrUrl) === 'string' ?
        optionsOrUrl :
        optionsOrUrl && optionsOrUrl.url;

    this.baseUrl = url;
    // Optional extraHeaders for all requests,
    // usually for authentication tokens
    this.extraHeaders = {};
    /**
     * Default data can be provided by setting properties here (incrementally
     * adding defaults), or providing another object (replacing previous defaults),
     * and every request specific data will be merged with this.
     * IMPORTANT: special 'files' request method not supported for this, or any
     * case where FormData is provided as 'data' object.
     * @property {Object}
     */
    this.defaultData = {};
}

Rest.prototype.get = function get(apiUrl, data) {
    return this.request(apiUrl, 'get', data);
};

Rest.prototype.put = function get(apiUrl, data) {
    return this.request(apiUrl, 'put', data);
};

Rest.prototype.post = function get(apiUrl, data) {
    return this.request(apiUrl, 'post', data);
};

Rest.prototype.delete = function get(apiUrl, data) {
    return this.request(apiUrl, 'delete', data);
};

var processResult = function (xhr, retry, thisRest) {
    var promiseXhr = Promise.resolve(xhr)
    .catch(function(err) {
        // On authorization error, give oportunity to retry the operation
        if (retry && err.status === 401 && thisRest.onAuthorizationRequired) {
            var retryPromise = thisRest.onAuthorizationRequired(retry);
            if (retryPromise) {
                // It returned something, expecting is a promise:
                return Promise.resolve(retryPromise)
                .catch(function(){
                    // There is error on retry, just return the
                    // original call error
                    throw err;
                });
            }
        }
        // by default, continue propagating the error
        throw err;
    });

    promiseXhr.xhr = xhr;
    return promiseXhr;
};

/**
 * Make a request that includes files, for POST and PUT requests.
 * It set-ups some things specially to make it send binary and multipart.
 * Example:
 * // Instance of FormData
 * var data = new FormData();
 * // Append each file object from form, here only the first one
 * data.append('fieldNameForFile', jQuery('input[type=file]')[0].files[0]);
 * // Other plain data can be appended too
 * data.append('id', 2);
 * // From Martin Becker@SO:
 * // If you need to set the content type of the form-data field, you can
 * // [use the Blob class](https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects)
 * data.append('file-1', new Blob([fileContent], { type: "application/json"}))
 * // I guess previous tip can be used to create a file content in memory (no real file, no picked from form)
 * // Blob and FormData have very similar engine support, except that Blob is WebKitBlobBuilder
 * // on Android 3-4.4.4 (solved by using polyfill as https://github.com/eligrey/blob.js).
 *
 * See: based on [this StackOverflow answer](https://stackoverflow.com/questions/5392344/sending-multipart-formdata-with-jquery-ajax)
 * See: for old engines without FormData, [emulation is possible] (https://stackoverflow.com/a/5976031/1622346)
 * @param {string} apiUrl
 * @param {string} httpMethod Usually POST or PUT
 * @param {FormData} data Data as an instance of FormData that enables
 * inclusion of files
 */
Rest.prototype.files = function files(apiUrl, httpMethod, data) {

    var url = this.baseUrl + apiUrl;

    var xhr = $.ajax({
        url: url,
        cache: false,
        dataType: 'json',
        method: httpMethod,
        headers: this.extraHeaders,
        data: data,
        // IMPORTANT! With FormData is set by XHR
        // 'multipart/form-data' Not needed, native XHR sets that auto when
        // including files in a FormData, put it in jQuery will have bad results
        // and false value prevents jQuery from setting a default.
        contentType: false,
        // IMPORTANT! Prevent jQuery from manipulate data
        processData: false,
    });

    var retry = files.bind(this, apiUrl, httpMethod, data);
    return processResult(xhr, retry, this);
};

Rest.prototype.request = function request(apiUrl, httpMethod, data, contentType) {

    var url = this.baseUrl + apiUrl;

    if (!(data instanceof FormData)) {
        data = $.extend({}, this.defaultData, data);
    }

    // URLENCODED input:
    // Convert to JSON and back just to ensure the values are converted/encoded
    // properly to be sent, like Dates being converted to ISO format.
    //data = data && JSON.parse(JSON.stringify(data));
    //contentType = contentType || 'application/x-www-form-urlencoded';

    // JSON input:
    contentType = contentType || 'application/json';
    data = data && JSON.stringify(data);
    // For GET methods, we need ever and object data for the querystring
    if (httpMethod === 'get')
        data = data && JSON.parse(data);

    // Using a promise to avoid the differences and problems of the jQuery thenable
    // object, but attaching its original value as a new property 'xhr' of the promise
    // created for advanced use.
    var xhr = $.ajax({
        url: url,
        // Avoid cache for data.
        cache: false,
        dataType: 'json',
        method: httpMethod,
        headers: this.extraHeaders,
        data: data,
        contentType: contentType
    });

    var retry = request.bind(this, apiUrl, httpMethod, data, contentType);
    return processResult(xhr, retry, this);
};

Rest.prototype.onAuthorizationRequired = function onAuthorizationRequired(/*retry*/) {
    // To be implemented outside, if convenient executing:
    //retry();
    // by default don't wait for retry, just return nothing:
    return;
};

module.exports = Rest;
