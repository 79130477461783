/**
 * Convert a plain text in multiline HTML by replacing new lines with the
 * break line element (<br/>).
 * Take care of encode the input text so the result can be safely used as the
 * inner HTML of an element where the only tags available are the `br` ones
 * introduced here (even any of this coming in the original text will get
 * encoded, displaying literally).
 */

function encodeHtml(text) {
    var f = document.createElement('div');
    f.innerText = text;
    return f.innerHTML;
}

function putBreakLines(text) {
    return text.replace(/\n/g,'<br/>');
}

/**
 * Replace new lines with break-lines elements, preventing XSS attacks by
 * encoding any other tag.
 * @param {string} text
 */
export default function(text) {
    return putBreakLines(encodeHtml(text));
}
