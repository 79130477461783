/**
 * App entry point:
 * - init global modules, settings
 * - set-up shell/router
 * - boot app
 * - showing first activity
 * - and hide loading screen when done
 */

/** Global dependencies **/
import './init/polyfills';
import './init/knockout';
import './init/bootstrap';
import * as activitiesIndex from './activities';
import * as shell from './init/shell';
import boot from './init/boot';
import { init as initCordova } from './init/cordova';
import { init as initMenu } from './init/appMenu';

boot(async () => {
    await initCordova();
    initMenu();
    // Start shell
    shell.create(activitiesIndex).run();
});
