/**
    Espace a string for use on a RegExp.
    Usually, to look for a string in a text multiple times
    or with some expressions, some common are
    look for a text 'in the beginning' (^)
    or 'at the end' ($).

    Author: http://stackoverflow.com/users/151312/coolaj86 and http://stackoverflow.com/users/9410/aristotle-pagaltzis
    Link: http://stackoverflow.com/a/6969486
**/
'use strict';

// Referring to the table here:
// https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/regexp
// these characters should be escaped
// \ ^ $ * + ? . ( ) | { } [ ]
// These characters only have special meaning inside of brackets
// they do not need to be escaped, but they MAY be escaped
// without any adverse effects (to the best of my knowledge and casual testing)
// : ! , =
// my test "~!@#$%^&*(){}[]`/=?+\|-_;:'\",<.>".match(/[\#]/g)

var specials = [
    // order matters for these
    "-",
    "[",
    "]",
    // order doesn't matter for any of these
    "/",
    "{",
    "}",
    "(",
    ")",
    "*",
    "+",
    "?",
    ".",
    "\\",
    "^",
    "$",
    "|"
];

// I choose to escape every character with '\'
// even though only some strictly require it when inside of []
var regex = RegExp('[' + specials.join('\\') + ']', 'g');

var escapeRegExp = function (str) {
return str.replace(regex, "\\$&");
};

module.exports = escapeRegExp;

// test escapeRegExp("/path/to/res?search=this.that")
