/**
 * Cordova initialization for plugins, to call in between deviceready and
 * app ready.
 */
import * as timer from '../vendor/iagosrl/timer';

/**
 * @returns {Promise}
 */
export function init() {
    if (!window.cordova) return Promise.resolve();

    if (window.StatusBar) {
        // Fix iOS-7+ overlay problem, and customize it
        // Settings at config.xml don't work well (tested again on v2.4.2, get applied
        // when splashscreen ends and suddenly switch back to defaults, should be a bug,
        // so we apply here the same values so keep unchanged).
        window.StatusBar.overlaysWebView(false);
        // background like our top navbar
        window.StatusBar.backgroundColorByHexString('#002F63');
        window.StatusBar.styleBlackOpaque();
    }

    // Small delay to allow the 'statusbar overlay' make the fix
    return timer.timeout(60);
}
