/**
 * REST API client data driver.
 * Provides a REST client instance ready
 * to use with the app set-up
 */
'use strict';

import Rest from '../../vendor/iagosrl/Rest';

const API_URL = localStorage.apiUrl || 'https://app.fishfarmfeeder.com/api/';

/**
 * Exposed rest client
 */
const rest = new Rest(API_URL);

export default rest;

/**
 * Sets the authorization header value for all future requests
 * @param {string} authorizationValue
 */
rest.setAuthorization = function(authorizationValue) {
    rest.extraHeaders = rest.extraHeaders || {};
    rest.extraHeaders.Authorization = authorizationValue;
};

/**
 * Sets the authorization header to be Bearer with the given token, for all
 * future requests
 * @param {string} token
 */
rest.setBearerAuthenticationToken = function(token) {
    rest.setAuthorization('Bearer ' + token);
};

/**
 * Clear the authorization value for all future requests
 */
rest.clearAuthorization = function() {
    if (rest.extraHeaders) {
        delete rest.extraHeaders.Authorization;
    }
};
