/**
    Knockout Binding to set CSS Transform values inline (modifing style attribute).
    Benefits against using the 'style' binding:
    - it uses the prefixes needed for the running browser. (requires jQuery >=1.8)
    - set every transform function as properties of the object used as value for the binding,
    rather than manually compose the resulting string with the style binding.
    - CSS required parenthesis around values are added automatically
    - both, the whole binding object and each property values can be observables or plain values.

    Example:
    data-bind="cssTransform: { translateX: '10px', rotate: '10deg', translateY: '5px' }"
    result: style="transform: translateX(10px) rotate(10deg) translateY(5px);"

    Version 1.0 "Raw values" Initial implementation that focus in have a first, working
    and usable binding that lets pass observable data structured by each function name
    with 'raw values' as strings, and functions provided with standard names that
    are autoprefixed by jQuery. No values, no keys/function names are validated.

    TODO:
    - When a number is passed as a value, it will get converted to a string
    adding 'px' to the end of that string. If another unit is required, pass in the
    value as a string with the unit appended.
    - Allow for arrays as values, where every element is converted to a value passed to
    the transform function in the same order, separated by commas as defiend at CSS.
    - Allow for objects as arrays, with a similar purpose as array but behaving as
    'named values', that the binding knows how to stringify in the correct order and
    filling holes/defaults when needed.
    - Validate available transform functions (keys at the binding object) against CSS standard,
    and number of possible values when passed in as arrays or objects (keep strings as
    no validated 'raw value')
**/
'use strict';

import $ from 'jquery';
import ko from 'knockout';

ko.bindingHandlers.cssTransform = {
    update: function(element, valueAccessor/*, allBindings*/) {
        var valuesObject = ko.unwrap(valueAccessor()) || {};
        var valueStr = Object.keys(valuesObject).map(function(key) {
            return key + '(' + ko.unwrap(valuesObject[key]) + ')';
        }).join(' ');
        // jQuery > 1.9 will auto-fill the vendor prefixes for you depending on which browser is being used
        $(element).css({
            transform: valueStr
        });
    }
};
