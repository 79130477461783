/**
 * Promise version of times 'setTimeout', 'setImmediate',
 * with additional 'clear' method without parameters
 * to cancel them (internally, 'clearTimeout', 'clearImmediate')
 */

/**
 * Promise version of setTimeout
 * @param {Number} delay
 * @returns {Promise} Augmented with 'clear' method
 */
export function timeout(delay) {
    var ref = null;

    var p = new Promise(function(resolve) {
        ref = setTimeout(resolve, delay);
    });

    p.clear = function() {
        clearTimeout(ref);
    };

    return p;
}

/**
 * Promise version of requestAnimationFrame
 * @returns {Promise} Augmented with 'cancel' method
 */
export function requestAnimationFrame() {
    var ref = null;

    var p = new Promise(function(resolve) {
        ref = window.requestAnimationFrame(resolve);
    });

    p.cancel = function() {
        window.cancelAnimationFrame(ref);
    };

    return p;
}
