/**
 * Execute code to set-up the App when environment is ready (on DOM/Cordova ready)
 * and finalize the App boot process setting is as ready for the user.
 *
 * Must be used before the events emit or may not trigger.
 */

function onReady(callback) {
    document.addEventListener(window.cordova ? 'deviceready' : 'DOMContentLoaded', callback);
}

/**
 * @param {Function<(Promise|void)} setup Function to perform custom task to prepare the App.
 * May return a Promise to end the booting process async.
 */
export default function(setup) {
    onReady(async () => {
        // External setup
        await Promise.resolve(setup());
        // General set-up to put App as loaded and available
        document.getElementById('loadingLayer').setAttribute('hidden', 'true');
        // Hide native splash
        if (window.navigator && window.navigator.splashscreen) {
            window.navigator.splashscreen.hide();
        }
    });
}
