/**
 * Custom build of Bootstrap JS modules, included globally through entry point.
 *
 * NOTE: Tried to load this modules on-demand, only at modules that actually make
 * use of them rather than globally, but Bootstrap doesn't make it easy!
 * A mix of compiled and source modules must be used, exposing some things to
 * the global scope.
 */
import $ from 'jquery';
import Util from 'bootstrap/js/src/util';
// jQuery globally is required for every module
window.$ = window.jQuery = $;
// Bootstrap Util is requred globally (!!)
window.Util = Util;

// Modal
require('bootstrap/js/dist/modal');
