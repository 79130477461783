/**
 * Implementation of a Shell/ItemsManager using Knockout.
 *
 * Specifically, Knockout component binding is used, switching the component
 * dynamically on view/item change.
 */

import ko from 'knockout';

// Because the component binding requires almost a name all the time, or will throw,
// we create an empty fallback, that needs to be a registered component with a name
// we expect not to conflict with anything else:
const noItemFallback = 'unspecified-item-ko-items-manager';
ko.components.register(noItemFallback, {
    template: '<div></div>'
});

export default class KoItemsManager {
    /**
     * @param {Object} settings
     * @param {HTMLElement} settings.root Element that must contains the 'items' elements
     * @param {Function<string,Promise>} settings.componentLoaded Query function
     * used to know when the component identified by name is loaded, waiting for
     * the Promise to resolve. This enables async loading/on-demand, where the
     * current `shell.loader` doesn't fit well for this impl.
     * @param {string} [settings.pattern={name}] Text containing the placeholder `{name}`
     * that will get replaced with the active item name (`route.name`) to
     * generate the registered component name. Can be as easy as the same name,
     * or include prefixes or suffixes following a strict convention.
     */
    constructor({ root, componentLoaded, pattern = '{name}' }) {
        /**
         * Query when the item component has loaded, by route name.
         * @member {Function<string,Promise>}
         */
        this.componentLoaded = componentLoaded;
        /**
         * The element with the component binding that becomes the host of the
         * component for the current view.
         * @member {HTMLElement}
         */
        this.host = createComponentElement(root);
        /**
         * Observable name of the active item, used to match a component name.
         * @member {KnockoutObservable<string>}
         */
        this.activeItemName = ko.observable('');
        /**
         * Computed component name, based on the active item name and pattern.
         * Whenever this changes (cause of activeItemName), the component
         * will get recreated.
         * @member {KnockoutComputed<string>}
         */
        this.activeComponentName = ko.pureComputed(() => {
            const name = this.activeItemName();
            // Construct the name following pattern, or the fallback empty component
            return name ? pattern.replace('{name}', name) : noItemFallback;
        });
    }

    /**
     * Locate the DOM element that represents an item by the given name.
     * @param {string} name Per interface, unused on this impl.
     * @returns {HTMLElement}
     */
    find() {
        // For this impl., the element is ever the same, our component host
        return this.host;
    }

    /**
     * Locate the DOM element tha represents the active item.
     * @returns {HTMLElement}
     */
    getActive() {
        // For this impl., the element is ever the same, our component host
        return this.host;
    }

    /**
     * Request to change the active item.
     * The method receives the items to interchange as active or current,
     * the 'from' and 'to', and the shell instance that MUST be used
     * to notify each event that involves each item:
     * willClose, willOpen, ready, opened, closed.
     * At this impl., the element references at 'from' and 'to' is ever the
     * same 'host' element.
     * @param {HTMLElement} from Current active element to replace with 'to'
     * @param {HTMLElement} to Element that will become the new active one
     * @param {../vendor/iagosrl/shell/Shell} shell
     * @param {Object} state It receives the navigation new state, containing
     * the 'route' that matches the item to become 'active'; MUST be passed at events
     * so handlers has context state information.
     * @param {boolean} preventChangeFocus For accessibility aware implementations,
     * this let's know when the 'to' element MUST NOT be focused as part of this
     * operation (when `false`, SHOULD carefully focus the element so screen
     * readers are able to notify the user about the item/view change, otherwise
     * the user is not aware of the change and get lost)
     *
     * It's designed to be able to manage transitions, but this impl.
     * is as simple as 'replace in-place the old with the new'.
     *
     * NOTE: Accessibility not implemented, so last parameter is not handled
     */
    switch(from, to, shell, state/*, preventChangeFocus*/) {

        // Navigations inside the same item (sub-routes) trigger a 'switch' request
        // too, but means we don't need to do some task and some events don't need
        // to be notified.
        const itemChanged = this.activeItemName() !== state.route.name;

        if (itemChanged) {
            shell.emit(shell.events.willOpen, to, state);
            shell.emit(shell.events.willClose, from, state);
            // Because the components may manipulate the host element class names,
            // without knowing that in this case that element is reused, we need
            // to ensure we reset it by remembering the previous one until the
            // new is loaded..
            const prevClass = to.className.replace(/^\s|\s$/g, '');

            this.componentLoaded(state.route.name)
            .then(() => {
                this.activeItemName(state.route.name);
                // Schedule events to emit soon after KO have applied the changes
                // The use of KO microtask fails, using minimum timeout (ko.tasks.schedule)
                setTimeout(() => {
                    //.. and we end resetting the class names by removing previous ones
                    to.className = to.className.replace(prevClass, '');
                    // New item is ready
                    shell.emit(shell.events.itemReady, to, state);

                    shell.emit(shell.events.closed, from, state);
                    shell.emit(shell.events.opened, to, state);
                });
            });
        }
        else {
            // Still the same item, but is required to notify the 'ready' state
            shell.emit(shell.events.itemReady, to, state);
        }
    }

    /**
     * Required by interface, only used when using 'loader' that is not compatible
     * with this impl.; the constructor componentLoaded must be used, and the Shell
     * must not include a loader.
     */
    inject(/*name, html*/) {
        throw new Error('Injecting a template is not supported by KoItemsManager');
    }

    /**
     * Makes binding for the host element effective
     */
    init() {
        ko.applyBindings(this, this.host);
    }
}

/**
 * Creates and attachs to the document (at the given place) the element with
 * the dynamic component binding
 * @param {HTMLElement} root Element that will containt the host
 */
function createComponentElement(root) {
    const element = document.createElement('div');
    element.setAttribute('data-bind', 'component: { name: activeComponentName }');
    root.appendChild(element);
    return element;
}
