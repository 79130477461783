/**
 *  Get a given value wrapped in an observable or returns
 *  it if its already an observable or just a function.
 */
import ko from 'knockout';

export default function(obsOrValue) {
    if (typeof(obsOrValue) === 'function')
        return obsOrValue;
    else
        return ko.observable(obsOrValue);
}
