/**
 * Loader of available activities, routing by name.
 */

/**
 * Get the activity class for the given name.
 * @param {string} routeName Route or activity name
 * @returns {Promise<Activity>} Rejects if doesn't exists.
 */
export function get(routeName) {
    switch (routeName) {
        case 'home': return import('./home');
        case 'contact': return import('./contact');
        case 'configurator': return import('./configurator');
        case 'feeders': return import('./feeders');
        case 'datasheets': return import('./datasheets');
        case 'your-feeder': return import('./your-feeder');
        case 'social-media': return import('./social-media');
        default: Promise.reject(`The activity ${routeName} don't exist`);
    }
}
