/**
 * App global menu, to be initialized at boot and expose API for usage at
 * activities to manage the menu
 */
import '../komponents/nav/menu';
import ko from 'knockout';

const vm = {
    isOpen: ko.observable(false),
    activeEntry: ko.observable('')
};

export function init() {
    ko.applyBindings(vm, document.getElementById('appMenu'));
}

export function open() {
    vm.isOpen(true);
}

export const activeEntry = vm.activeEntry;
