/**
 * Show an error modal to notify the user.
 */

import getErrorMessageFrom from './getErrorMessageFrom';
import multilineText from '../helpers/multilineText';
import showModal from '../helpers/showModal';
import template from './template.html';

/**
 * Display a modal with an error message
 * @param {Object} options
 * @param {(string|Exception|Error|Object)} [options.error='There was an error'] Error message
 * or error object that will be analyzed to extract an error
 * message (@see getErrorMessageFrom for object patterns supported).
 * @param {string} options.title Text for the modal title bar.
 * @param {string} [options.buttonText='Close'] Text for the button that closes the modal.
 * @returns {Promise<string,any>} It resolves when the modal is closed returning
 * the error text that was displayed (usefull when the message was inferred from
 * a given object and want to be persisted or displayed in an additional way
 * after the modal).
 */
export function show(options) {
    return new Promise((resolve) => {
        const errMsg = getErrorMessageFrom(options.error);
        const hideIt = showModal(template, {
            title: options.title,
            message: multilineText(errMsg),
            buttonText: options.buttonText || 'Close',
            close: () => {
                hideIt();
            },
            onHide: () => {
                resolve(errMsg);
            }
        });
    });
}
